.carousel-3d {
  height: 80%;

  img {
    height: 100%;
  }

  &--indicators {
    box-sizing: content-box;
    padding: 1em 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 80px;
    background-color: rgba(#e2e2e2, 0.15);

    &--wrapper {
      display: flex;
      justify-content: center;
      position: relative;
      max-width: 90%;
      overflow: visible;

      img {
        opacity: 0.5;
        height: 80px;
      }

      img.--active {
        opacity: 1;
      }
    }
  }

  &--next,
  &--prev {
    svg path {
      fill: white;
    }
  }

  &--prev {
    left: -4em;
  }

  &--next {
    right: -4em;
  }
}
