.upload-container,
.file-container {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 16px;

  input[type='file'] {
    // hide and let label do the work
    z-index: -1;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  // overrides of the styleguide defaults
  .NEW__aedit-input-field,
  .NEW__aedit-select-field {
    max-width: 180px;
    margin-bottom: 12px;
    .V2__overline.contents-label {
      font-size: 10px;
      font-weight: 600;
      line-height: 1;
      margin: 0 0 4px 0;
      letter-spacing: 0.5px;
      text-transform: capitalize;
    }

    .input-wrapper {
      margin: 0 8px 0 0;
      input[type='text'] {
        margin: 0;
        height: 46px;
        font-size: 14px;
        padding: 8px;
        margin-right: 12px;
        background: white;
        border: 1px solid #eee;
      }

      textarea {
        height: 46px !important;
        background: white;
        border: 1px solid #eee;
      }
    }

    .Select {
      margin: 0;
    }

    .Select-control {
      border: 1px solid #eee !important;
      height: 44px;
      border-radius: 0;
      line-height: 4px;

      .Select-value {
        line-height: 44px;
      }
    }

    .state-label {
      font-size: 12px;
      top: 100%;
    }

    &.margin-override {
      margin-bottom: 0 !important;
    }

    &.full-width {
      width: 100%;
      max-width: 100%;
    }
  }
}

.upload-container {
  justify-content: center;
  cursor: pointer;
  min-height: 120px;
  border: 2px dashed #ddd;

  &:hover {
    background: #f9f9f9;
  }

  label {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.file-container {
  background: #f9f9f9;
  border: 1px solid #ddd;

  .image-wrapper {
    width: 100px;
    height: 100px;
    position: relative;
    flex-shrink: 0;
    margin-right: 24px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }

    label {
      position: absolute;
      top: -6px;
      right: -6px;
      background: white;
      padding: 4px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
      }
    }
  }

  .editable-content {
    flex-grow: 1;
    > section {
      display: flex;
    }
  }

  .icon-cross {
    position: absolute;
    top: -4px;
    right: -4px;
    z-index: 1;
    background: white;
    cursor: pointer;
  }
}
