// New
.modal-content.procedures-before-and-after-form {
  width: 800px !important;
}

#before-after-procedures-form {
  .button-wrapper {
    width: 100%;
    text-align: right;
  }
}
