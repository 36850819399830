// Tables
$th-height: 42px;

// custom fixed header table
.table-container {
  position: relative;
  padding-top: $th-height;
  flex-grow: 1;
  overflow: scroll;
  min-height: 0;
  max-height: 100%;

  .table-wrapper {
    min-height: 0;
    max-height: 100%;

    th {
      height: 0;
      line-height: 0;
      padding-top: 0;
      padding-bottom: 0;
      color: transparent;
      border: none;
      white-space: nowrap;
    }

    th > div {
      position: absolute;
      background: transparent;
      color: $body-font-color;
      top: 0;
      padding: $unit-3 $unit-2;
      margin-left: (-$unit-2);
      line-height: normal;
      transform: translateZ(0); // hack to fix flickering in chrome
    }
  }
}

.table {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
  width: 100%;

  &.table-striped {
    tbody {
      tr:nth-of-type(odd) {
        background: $bg-color;
      }
    }
  }

  &,
  &.table-striped {
    tbody {
      tr {
        &.active {
          background: $bg-color-dark;
        }
      }
    }
  }

  &.table-hover {
    tbody {
      tr {
        &:hover {
          background: #f9f9f9;
        }
      }
    }
  }

  // Tables with horizontal scrollbar
  &.table-scroll {
    display: block;
    overflow-x: auto;
    padding-bottom: 0.75rem;
    white-space: nowrap;
  }

  td,
  th {
    border-bottom: $border-width solid $border-color;
    padding: $unit-3 $unit-2;
    a {
      font-weight: 300;
    }
  }
  th {
    border-bottom-width: $border-width-lg;
  }
}
