.google-map-container {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;

  .loading-icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: rgba(white, 0.6);
  }

  .map-area {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .map {
      height: 100%;
    }
    > div > div {
      border: 6px solid white;
      border-bottom-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    .map-controls {
      padding: 14px 0;
    }
  }

  .map-marker {
    background: 'pink';
    width: 20px;
    height: 20px;
    border: 1px solid white;
    border-radius: 50%;
    position: relative;

    &-content {
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      background: white;
      width: 200px;
      height: 150px;
    }
  }

  .provider-list {
    width: 300px;
    overflow-x: auto;
    height: calc(100% - 64px);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    position: relative;
    background: white;
    display: flex;
    flex-direction: column;
    padding: 12px;

    .ais-SearchBox {
      margin-bottom: 8px;
      display: flex;

      form {
        display: flex;
        justify-content: space-between;
      }

      input {
        outline: none;
        padding: 8px;
        border: 2px solid #eee;
        letter-spacing: 0.5px;
      }

      button {
        @extend .btn;
        display: flex;
        align-items: center;
        margin-left: 6px;
      }
    }

    .ais-Stats-text {
      font-size: 12px;
      color: #999;
    }

    .ais-Hits {
      overflow: auto;
      margin-left: -12px;
      margin-right: -12px;
      padding: 12px;
      .ais-Hits-list {
        margin: 0;
      }

      &-item {
        margin: 0;
      }
    }

    .hit-item {
      padding: 12px;
      margin-left: -12px;
      margin-right: -12px;
      background: white;
      font-size: 14px;
      transition: all 200ms;
      border-bottom: 1px solid #eee;

      &:last-of-type {
        margin-bottom: 0;
      }

      &:hover {
        cursor: pointer;
        transform: scale(1.01);
        background: #f9f9f9;
      }

      strong {
        display: block;
        margin-bottom: 6px;
      }

      p {
        margin-bottom: 0;
        line-height: 1.3;
      }
    }

    .ais-Pagination {
      box-shadow: 0 -7px 7px -9px rgba(0, 0, 0, 0.5);
      position: relative;
      margin-left: -12px;
      margin-right: -12px;
      &-list {
        text-align: center;
      }

      &-item {
        display: inline-block;
        margin-left: 12px;

        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }
}
