.labeledFields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.agreement-version-display {
  display: flex;
  flex-direction: column;

  span {
    color: #555555;
    font-weight: 300;
    font-size: 15px;
    margin-bottom: 0.5rem;

    + span {
      margin-bottom: 0;
    }
  }
}
