.image-list {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  height: 0;

  &--controls {
    height: 1.8rem;
  }

  &--container {
    overflow-y: auto;
    flex: 1;
  }

  &--row {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;
    margin: 0;
    list-style: none;
    height: auto;

    .image-content {
      display: flex;
      position: relative;
      overflow: hidden;
      height: 100%;

      &:hover {
        cursor: pointer;
      }

      > section {
        display: flex;
        flex-direction: column;
      }
    }

    .remove-button {
      position: absolute;
      top: 6px;
      right: 6px;
      font-size: 35px;
      color: red;

      &:hover {
        cursor: pointer;
        color: rgba(red, 0.5);
      }
    }

    .image {
      width: 100%;

      &:hover {
        cursor: pointer;
      }
    }

    .media-caption {
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
      justify-content: center;
      align-items: flex-start;
      padding: 16px 8px;
      background: rgba($black, 0.4);
      color: $white;
      cursor: default;

      svg {
        margin: 0 8px;
      }

      * {
        color: $white;
      }
    }
  }

  .caption-item {
    display: flex;
    font-size: 12px;
    font-weight: 300;
    cursor: default;
    letter-spacing: 0;
    margin-top: 8px;
    max-width: 100%;
  }

  .modal-image {
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
    height: 80vh;

    .badge {
      position: absolute;
      right: -5px;
      top: 4px;
    }

    .icon {
      position: absolute;
      top: 50%;
      font-size: 45px;
      color: rgba($white, 0.55);
      cursor: pointer;

      &-arrow-right {
        right: -50px;
      }

      &-arrow-left {
        left: -50px;
      }

      &:hover {
        color: $white;
      }
    }

    img {
      height: 100%;
    }

    .subject-email {
      position: absolute;
      bottom: 100%;
      color: coral;
      font-size: 14px;
      letter-spacing: 0.5px;
    }
  }

  .modal-caption,
  .detection-classification-data {
    color: $white;
    position: absolute;
    left: 50%;
    bottom: -30px;
    transform: translateX(-50%);
    text-align: center;
    width: 100%;
    padding-top: 15px;
    margin-top: -15px;
    background: rgba(0, 0, 0, 0.5);
  }

  .detection-classification-data {
    top: 0;
    left: calc(100% + 30px);
    transform: translateX(0);
    width: fit-content;
    text-align: left;
    font-size: 14px;
    margin-left: 20px;
    overflow-y: scroll;

    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      margin-left: 10px;

      &:last-of-type {
        margin-bottom: 10px;
      }
    }
  }
}

// Trying to be cute
.loader {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  transform: translateY(-50%);
  display: block;
  margin: 0 auto;

  &--first {
    height: 20px;
    text-align: center;

    .line {
      opacity: 0.5;
      display: inline-block;
      margin: 0 2px;
      width: 4px;
      height: 100%;
      background-color: white;
      border-radius: 2px;
      animation: firstAnimation 2s ease-in infinite;
    }

    .line:nth-child(1) {
      animation-delay: 0s;
    }
    .line:nth-child(2) {
      animation-delay: 0.15s;
    }
    .line:nth-child(3) {
      animation-delay: 0.3s;
    }
    .line:nth-child(4) {
      animation-delay: 0.45s;
    }
    .line:nth-child(5) {
      animation-delay: 0.6s;
    }
  }
}

@keyframes firstAnimation {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  20% {
    transform: scale(1, 1.3);
    opacity: 1;
  }
  40% {
    transform: scale(1);
    opacity: 0.5;
  }
}
