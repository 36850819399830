// Accordions
.accordion {
  input:checked ~,
  &[open],
  &.open {
    & .accordion-header {
      .icon {
        transform: rotate(90deg);
      }
    }

    & .accordion-body {
      max-height: 50rem;
    }
  }

  .accordion-header {
    display: block;
    padding: $unit-1 $unit-2;
    font-size: .65rem;
    font-weight: 600;
    cursor: pointer;
    text-transform: uppercase;
    &:hover {
      text-decoration: none;
    }

    .icon {
      transition: all .2s ease;
    }
  }

  .accordion-section {
    padding: $unit-1 $unit-2;
  }

  .accordion-body {
    margin-bottom: $layout-spacing;
    max-height: 0;
    overflow: hidden;
    transition: max-height .2s ease;
  }
}

// Remove default details marker in Webkit
summary.accordion-header {
  &::-webkit-details-marker {
    display: none;
  }
}
