.image-list {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    flex: 1 0 auto;
    height: 0;


    .close-icon {
      position: absolute;
      right: 24px;
      top: 24px;
      color: #fff;
      background: #000;
      border-radius: 8px;
      z-index: 10;
      font-size: 32px;
      cursor: pointer;
    }
  
    &--controls {
      height: 1.8rem;
    }
  
    &--container {
      overflow-y: auto;
      width: 100%;
      flex: 1;
    }
  
    &--row {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 12px;
      margin: 0;
      list-style: none;
      height: auto;
  
      .image-content {
        display: flex;
        position: relative;
        overflow: hidden;
        width: 100%;

        .group-total-count {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 5%;
            left: 5%;
            background-color: #333;
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
            color: white;
            font-weight: 600;
            border-radius: 12px;
            font-size: 0.75rem;

            padding: 8px 14px;

            :first-child {
                margin-right: 0.4rem;
            }
        }

        &:hover {
          cursor: pointer;
        }
      }

      .image {
        width: 100%;
  
        &:hover {
          cursor: pointer;
        }
      }
    }
  
    .classification-image {
      position: fixed;
      top: 45%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      z-index: 4;
      height: 80vh;
      display: flex;
      justify-content: center;

      .icon-container {
        height: 100%;
        display: flex;
        align-items: center;
        margin: 0 10px;
      }

      .disabled {
        color: rgba($white, 0.1) !important;
      }

      .icon {
        font-size: 45px;
        color: rgba($white, 0.7);
        cursor: pointer;

        &:hover {
          color: $white;
        }
      }
  
      img {
        height: 100%;
      }
    }


    .parent-info,
    .classification-data {
      color: $white;
      background-color: #000;
      margin-top: -8px;
      width: fit-content;
      font-size: 14px;
      max-width: 300px;
    }

    .parent-info {
      text-align: center;
      background-color: black;
      padding: 18px 16px;

      scrollbar-width: none;

      .caption-item {
        font-size: 14px;
      }

      .caption-item + .caption-item {
        margin-top: 12px;
      }
    }
  
    .classification-data {
      text-align: left;
      overflow-y: scroll;
      scrollbar-width: none;
      padding: 18px 16px;

      .classifications {
        display: flex;
        flex-direction: column;
        margin-left: 0;

        .classification-item {
          background-color: lightgrey;
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #333;
          margin: 4px 8px 8px;
          padding: 4px 8px;
          border-radius: 6px;
          min-width: 180px;
        }

        .c-type,
        .c-val {
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 4px;
        }
        .c-type {
          width: 100%;
          font-size: 16px;
          padding: 6px 0 8px;
        }
        
        .c-val {
          border-top: 1px solid #333;
          padding: 8px 0 6px;
          font-size: 14px;
          width: 85%;
        }
      }
  
      &::-webkit-scrollbar {
        display: none;
      }
  
      li {
        margin-left: 10px;
  
        &:last-of-type {
          margin-bottom: 10px;
        }
      }
    }
  }
  