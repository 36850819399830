// Tooltips
.tooltip {
  position: relative;
  &::after {
    background: rgba($dark-color, .95);
    border-radius: $border-radius;
    bottom: 100%;
    color: $light-color;
    content: attr(data-tooltip);
    display: block;
    font-size: 11px;
    left: 50%;
    width: $control-width-sm;
    opacity: 0;
    overflow: hidden;
    padding: 4px 6px;
    pointer-events: none;
    position: absolute;
    transform: translate(-50%, $unit-2);
    transition: all .2s ease;
    z-index: $zindex-3;
    letter-spacing: 0.3px;
    font-family: arial;
    text-align: center;
  }
  &:focus,
  &:hover {
    &::after {
      opacity: 1;
      transform: translate(-50%, -$unit-1);
    }
  }
  &[disabled],
  &.disabled {
    pointer-events: auto;
  }

  &.tooltip-right {
    &::after {
      bottom: 50%;
      left: 100%;
      transform: translate(-$unit-1, 50%);
    }
    &:focus,
    &:hover {
      &::after {
        transform: translate($unit-1, 50%);
      }
    }
  }

  &.tooltip-bottom {
    &::after {
      bottom: auto;
      top: 100%;
      transform: translate(-50%, -$unit-2);
    }
    &:focus,
    &:hover {
      &::after {
        transform: translate(-50%, $unit-1);
      }
    }
  }

  &.tooltip-left {
    &::after {
      bottom: 50%;
      left: auto;
      right: 100%;
      transform: translate($unit-2, 50%);
    }
    &:focus,
    &:hover {
      &::after {
        transform: translate(-$unit-1, 50%);
      }
    }
  }
}
