@import '~@aedit/styleguide/dist/stylesheet';
@import './vendor/spectre.scss';
@import './vendor/toastr/index.scss';
@import './before_after.scss';

$red: #ce6e6c;
$white: #ffffff;
$green: #5ad086;
$black: #000000;
$blue: #08c;

// Setup glob importer before this grows too much
@import '../components/image_list/index.scss';
@import '../components/multimedia/prescan_list/index.scss';
@import '../components/image_list/components/morph/carousel/index.scss';

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// custom
.ais-SortBy-select {
  appearance: none;
  background: transparent;
  display: block;
  padding: 8px 6px 5px 0;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  background-image: url('https://res.cloudinary.com/aedit/image/upload/v1555524056/website/pages/all/caret_down.svg');
  background-repeat: no-repeat;
  background-position: 100% center;
}

// React Select override
.Select-control {
  overflow: auto !important;
}
.Select-input > input {
  margin-top: 0 !important;
}

.Select--multi {
  margin-bottom: 1.5rem;
}

.flex-holder {
  display: flex;
  background: #f0f0f0; //#f8f9fa;
}

.flex-fill {
  height: 0;
  flex-grow: 1;
}

.well {
  background: $white;
  border: 1px solid #e5e5e5;
}

.panels {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.link {
  color: $blue;
  text-decoration: underline;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.invisible {
  visibility: hidden;
}

.hidden {
  display: none;
}

.controls {
  margin-bottom: 10px;
  h4 {
    margin-bottom: 0;
    margin-top: 5px;
  }
}

.loading-icon,
.empty-state {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .fa {
    font-size: 40px;
  }

  > img {
    width: 100%;
    height: 100%;
    max-width: 40px;
    max-height: 40px;
  }
}

.breadcrumb .breadcrumb-item {
  font-size: 14px;
}

.tab .tab-item a,
.tab .tab-item span {
  font-size: 14px;
  text-transform: uppercase;
}

.side-nav-container {
  width: 270px;
  height: 100vh;
  background: $white;
  padding: 10px 15px;
  flex-shrink: 0;
  border-right: 1px solid #eee;

  .side-nav-contents {
    height: 100%;
    overflow-y: scroll;

    .logo {
      display: block;
      width: 100px;
      margin: 20px auto 40px auto;
    }

    .accordion-header {
      text-decoration: none;
      font-size: 0.7rem;
      padding: 0.4rem;
      color: #50596c;

      &.active {
        color: $primary-color;
      }

      i.icon,
      svg {
        // svg to cover fontawesome 5 icons
        margin-right: 15px;
      }
    }

    .accordion-body {
      .menu-nav {
        padding-top: 0;
      }

      .menu-item a {
        margin: 0 0.6rem 0 0.8rem;
      }
    }
  }
}

.navbar {
  padding: 0;
}

.flex-column {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  flex-grow: 1;
  height: 100vh;
  max-width: calc(100% - 270px); // subtract side nav width

  .main-section {
    display: flex;
    flex: 1 1 auto;
    height: 100%;
    overflow-x: hidden;

    .primary-content,
    .secondary-content {
      display: flex;
      flex-direction: column;
    }

    .primary-content {
      flex-grow: 1;
      height: 100%;
      max-width: 100%;
    }

    .secondary-content {
      width: 225px;
      flex-shrink: 0;
      margin-left: 15px;
    }
  }
}

.form-fields {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  background: #f0f0f0;
  overflow: auto;
}

.form-fields-button {
  outline: none;
}

.pagination .page-item:not(.disabled) {
  cursor: pointer;
}

.page-form {
  border-radius: 3px;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-bottom: 10px;
  .form-group {
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 0;
    min-height: 75px;
    flex-shrink: 0;

    label:not(.form-radio) {
      font-size: 14px;
      line-height: 1.4;
      padding: 0;
      color: #777;
    }

    > .label-holder {
      text-align: right;
      padding-right: 20px;
      background: #f9f9f9;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border-right: 1px solid #f2f2f2;
    }

    .form-input {
      border: none;

      &.error {
        box-shadow: 0 0 0 0.1rem rgba($red, 0.3);
      }
    }

    > .input-holder {
      padding-left: 20px;
      display: flex;
      align-items: center;
      flex-grow: 1;
      position: relative;
      background: #f9f9f9;

      &.white-bg {
        background: $white;
        flex-grow: 0;
      }

      > span {
        color: #555;
        font-weight: 300;
        font-size: 15px;

        &.field-error {
          font-size: 11px;
          position: absolute;
          left: 20px;
          bottom: 1px;
          color: $red;
          text-transform: none;
        }

        &.char-data {
          position: absolute;
          right: 0;
          bottom: 1px;
          color: #999;
          font-size: 11px;

          .danger {
            color: $red;
          }
        }
      }

      .inline-field {
        margin-right: 10px;
        box-shadow: 0 0 0 0.1rem rgba(171, 152, 137, 0.2);
      }

      .order-field {
        margin: 0 10px;
        width: 150px;
        position: relative;

        input[type='text'] {
          box-shadow: 0 0 0 0.1rem rgba(171, 152, 137, 0.2);
        }

        &.author-link-field {
          width: 100%;
        }

        .field-error {
          position: absolute;
          top: calc(100% + 2px);
          left: 0;
          color: $red;
          font-size: 11px;
        }
      }

      &.fill {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 20px;
        padding-bottom: 20px;
        height: 100%;
      }
    }

    textarea {
      height: 70px; // necessary to specify for transition
      transition: all 300ms ease;
      font-size: 14px;
      background: $white;
      resize: none;
      margin-top: 10px;
      margin-bottom: 20px;

      &.fill {
        margin: 0;
        padding: 0;
        height: 100% !important;
      }

      &:focus {
        height: 300px;
      }
    }

    .form-select {
      &.error {
        border: 1px solid $red;
      }
    }

    .madlib {
      padding-left: 25px;
      label {
        line-height: 70px;
      }

      .form-select {
        display: inline-block;
        position: relative;
        top: -2px;
        width: 120px;
        margin-left: 5px;
        margin-right: 5px;
      }

      .form-input {
        margin: 0 6px;
        display: inline-block;
        box-shadow: 0 0 0 0.1rem rgba(171, 152, 137, 0.2);

        &[type='number'] {
          width: 60px;
          text-align: center;
        }

        &[type='text'] {
          width: 200px;
          text-align: left;
          margin-left: 20px;
        }

        &.error {
          box-shadow: 0 0 0 0.1rem rgba($red, 0.4);
        }
      }

      .field-error {
        display: none;
      }
    }
  }
}

.fa-trash {
  cursor: pointer;
  opacity: 0.75;
  &:hover {
    opacity: 1;
  }
}

.form-footer {
  text-align: right;
  padding: 15px;
  border-top: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  margin-top: auto;

  .btn {
    margin-left: 10px;
  }

  .invalid-hint {
    font-size: 12px;
    font-style: italic;
  }
}

.rw-widget {
  &.error-state {
    box-shadow: 0 0 0 0.1rem rgba($red, 0.3);
    border-radius: 2px;
  }
  .rw-widget-container {
    border: none;
    box-shadow: none;
  }
}

.g-places-container {
  position: relative;
  display: block;
  width: 100%;

  .search-input,
  .search-input:focus,
  .search-input:active {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
    border: honeydew;
    display: block;
    width: 100%;
    padding: 16px;
    font-size: 16px;
    border-radius: 2px;
    outline: none;
  }

  .autocomplete-container {
    border-bottom: honeydew;
    border-left: honeydew;
    border-right: honeydew;
    border-top: 1px solid #e6e6e6;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 0 0 2px 2px;
    position: absolute;
    top: 100%;
    z-index: 999;
  }

  .suggestion-icon {
    margin-right: 8px;
  }

  .suggestion-item {
    padding: 4px;
    text-align: left;
  }
}

.Select {
  width: 100%;
}

.form-label.helper {
  text-decoration: underline dotted #333;
  cursor: pointer;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.modal-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  background: rgba($black, 0.95);
}

.modal-content {
  overflow: scroll;
  border-radius: 3px;
  width: 600px !important;

  .field-error {
    color: $red;
    font-size: 12px;
    display: block;
  }

  input,
  select,
  .Select {
    margin-top: 20px;
  }

  textarea {
    // do not automatically change height
    // (otherwise it is impossible to click on a button that is placed immediately below the textarea)
    height: 300px !important;
  }

  .dual-fields {
    display: flex;

    > div {
      flex-grow: 1;
      flex-basis: 0;
      width: calc(50% - 10px);

      &:first-of-type {
        margin-right: 20px;
      }
    }

    p {
      font-size: 12px;
      margin-bottom: 5px;
      font-style: italic;
    }

    img {
      height: 150px;
      width: 100%;
      object-fit: cover;
    }
  }

  .remove-image {
    font-size: 12px;
    position: relative;
    top: -4px;
    display: block;
    text-decoration: underline;
    color: $blue;
    cursor: pointer;
  }
}

.pic-preview,
.delete-image {
  height: 70px;
  right: 0;
  padding: 0;
}

.delete-image {
  position: absolute;
  border-radius: 3px;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }
}

.delete {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  padding-top: 12.5px;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 1.2px;
  color: transparent;
  transition: all 0.35s ease-in-out;
  width: 100%;
  height: 100%;

  &:hover {
    color: rgba($white, 0.75);
    background-image: linear-gradient(to bottom, rgba($primary-color, 0.5), rgba($black, 0.75));
  }
}

.copy {
  font-size: 20px;
  margin-left: 15px;
}

.react-datepicker__day.react-datepicker__day--outside-month {
  opacity: 0.25;
}

.react-datepicker__input-container input {
  width: 300px;
}

.Select-menu-outer {
  z-index: 1000 !important;
}

.synonyms-container {
  position: relative;

  svg {
    cursor: pointer;
  }

  .text-field {
    border: none;
    background-color: transparent;
    width: 100%;
    display: block;
    padding: 3px;
    border-radius: 2px;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      background-color: $white;
      outline: none;
    }
  }

  .delete-all-container {
    background-color: $white;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    span {
      display: block;
      margin-bottom: 20px;
    }
  }
}

.flagged-reason,
.flagged-reason-dropdown {
  position: absolute;
  background-color: rgba($black, 0.4);
  color: white;
  text-transform: capitalize;
}

.flagged-reason {
  white-space: nowrap;
}

.flag,
.flag-selected {
  position: absolute;
  top: 6px;
  left: 6px;
  font-size: 35px;

  .flagged-reason {
    top: 9px;
    left: 32px;
    font-size: 14px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.flagged-reason-dropdown {
  left: 45px;
  top: 13px;
  outline: none;
  text-align-last: center;
  padding: 2.5px;
  border-radius: none;
  border: none;
}

.flag {
  color: rgba($white, 0.75);
  font-size: 26px;

  &:hover {
    cursor: pointer;
    color: rgba(red, 0.5);
  }

  &-selected {
    color: red;
    font-size: 26px;
  }
}

.justify-space-between {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  padding-right: 10px;
}

.author-form-fields {
  border: none;
  outline: none;
  width: 100%;
}

.publication-year {
  padding: 0;
  height: 28px;
}

.save-link:hover {
  cursor: pointer;
}

.link-form-left {
  padding-right: 20px;
}

.link-submit-button {
  margin-left: 20px;
}

.link-form-right,
.link-form-left {
  margin-bottom: 20px;
  width: 100%;
  display: relative;

  input[type='text'] {
    box-shadow: 0 0 0 0.1rem rgba(171, 152, 137, 0.2);
  }

  .field-error {
    font-size: 11px;
    color: $red;
    position: absolute;
    display: block;
  }

  .link-field,
  .link-year-dropdown {
    margin-top: 20px;
  }
}

.approved-images {
  text-align: center;
  margin-top: 14px;
}

.timeline-dropdown {
  width: 130px;
}

.truncate {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

th.stats-table-header {
  border-bottom-color: transparent;
}

.training-button {
  display: flex;
  position: relative;
  padding: 5px 8px;
  margin: 0 auto;
  font-size: 12px;
  font-weight: 400;
  background: rgba($black, 0.4);
  overflow: hidden;
  cursor: pointer;
  border-radius: 4px;

  &-red {
    background-color: rgba(red, 0.5);
  }

  &-green {
    background-color: rgba(green, 0.5);
  }

  &-modal {
    border: 1px solid $white;
    width: 100px;
  }
}

.morph-wrapper {
  width: 500px;
  position: relative;

  img {
    width: 100%;
  }

  .image-recognition-point {
    position: absolute;
    width: 4px;
    height: 4px;
    background: red;
    border-radius: 50%;
  }
}

.stats {
  margin-bottom: 30px;

  section {
    display: inline-block;
    width: 200px;
    border: 1px solid #dde;
    border-radius: 3px;
    padding: 15px;
    margin-right: 20px;
    text-align: center;

    > strong {
      display: block;
      font-size: 14px;
      margin-bottom: 5px;
      color: #9ea6b7;
    }

    > span {
      display: block;
      font-size: 24px;
      font-weight: 300;
    }
  }
}

.d__block {
  display: block;
  width: 100%;
}

li.sortable-item {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  list-style: none;
  margin-top: 0;
  vertical-align: top;
  cursor: grab;
  border: 1px solid #ccc;
  border-radius: 3px;
  position: relative;
  min-width: 200px;
  max-width: 300px;

  .sortable-item-contents {
    overflow: hidden;
    text-align: center;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 40px;
      background: linear-gradient(to bottom, rgba(137, 255, 241, 0) 0%, rgba(0, 0, 0, 0.85) 100%);
    }

    img {
      height: 285px;
      display: inline-block;
    }

    video {
      height: 285px;
      display: inline-block;
    }

    .edit-icon {
      position: absolute;
      bottom: 7px;
      right: 7px;
      cursor: pointer;
      transition: transform 300ms ease;
      color: white;
      z-index: 2;

      &:hover {
        transform: scale(1.2);
      }
    }

    .delete-icon {
      position: absolute;
      top: 7px;
      right: 7px;
      cursor: pointer;
      transition: transform 300ms ease;
      color: darkred;
      z-index: 2;

      &:hover {
        transform: scale(1.2);
      }
    }

    .sort-index {
      color: white;
      position: absolute;
      bottom: 5px;
      left: 7px;
      font-size: 13px;
      font-weight: 900;
      z-index: 2;
    }

    .caption {
      color: white;
      position: absolute;
      display: block;
      bottom: 7px;
      font-size: 13px;
      font-weight: 900;
      z-index: 1;
      width: 100%;
      text-align: center;
    }
  }

  .meta-fields {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #f7f7f7;
    display: flex;
    flex-direction: column;
    padding: 10px;
    overflow: auto;

    .close-icon {
      position: absolute;
      top: -2px;
      right: 4px;
      font-size: 20px;
      cursor: pointer;

      svg {
        color: #999;
      }
    }

    label {
      font-size: 10px;
      text-transform: uppercase;
      margin-bottom: 6px;

      &.radio-label {
        display: inline-block;
        margin-right: 10px;
        &:last-of-type {
          margin-right: 0;
          margin-bottom: 10px;
        }

        input {
          display: inline-block;
          width: auto;
          margin-right: 4px;
        }
      }

      input {
        display: block;
        width: 100%;
        border: 1px solid #ddd;
        padding: 5px;
        border-radius: 3px;
        outline: none;
      }
    }

    .action-items {
      display: flex;
      justify-content: space-between;
      flex-shrink: 0;
    }
  }
}
