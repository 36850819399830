.date-picker__header {
  span,
  svg {
    width: 24px !important;
    height: 24px !important;
  }

  svg {
    cursor: pointer !important;
  }
}
