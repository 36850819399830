.flame-filters {
  padding: 12px;

  .flame-filters-container {
    display: flex;
  }
}

.flame-filter {
  flex: 1;

  &-controls {
    display: flex;
    flex-direction: row;
    padding-bottom: 36px;
  }

  &:not(&:last-of-type) {
    margin-right: 8px;
  }

  &-btn {
    flex: 1;

    &:not(&:last-of-type) {
      margin-right: 8px;
    }
  }

  .flame-param-slider {
    padding-bottom: 24px;

    .slider {
      min-height: 36px;
      vertical-align: baseline;

      > * {
        vertical-align: middle;
      }

      > input {
        width: 70%;
        margin-right: 8px;
      }
    }

    .flame-param-custom-range {
      padding-bottom: 8px;

      & > div {
        display: flex;
        white-space: nowrap;
        align-items: center;
        margin-bottom: 8px;
      }

      label {
        width: 50px;
      }
    }
  }
}

