.provider-review-form {
  display: flex;
  flex-direction: column;
  padding: 0 0 20px;
  width: 60vw;
  max-width: 100%;

  .review-input-field {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 16px 0;
  }
}
