.credit-card-form {
  padding: 19px 4px 11px; // modal has padding: 5px 20px 13px;

  &__main {
    margin-top: 32px;

    .NEW__aedit-input-field {
      .input-wrapper {
        &--dual {
          display: flex;

          > * {
            flex: 1;

            &:not(:last-child) {
              margin-right: 8px;
            }
          }
        }

        input {
          height: 30px;
          padding: 8px;
          font-size: 14px;
          line-height: 14px;
        }

        .StripeElement {
          background-color: rgba(8, 48, 48, 0.03);

          height: 30px;
          padding: 8px;
          font-size: 14px;
          line-height: 14px;
          width: 100%;
          border: none;
          color: black;
          border-bottom: 2px solid transparent;

          &--focus {
            border-bottom: 2px solid black;
          }
        }
      }
    }

    .V2__aedit__button-input {
      .input-container {
        p {
          font-size: 14px;
          margin-bottom: 0;
        }
      }
    }
  }

  &__buttons {
    margin-top: 32px;

    display: flex;
    justify-content: flex-end;

    .V2__btn {
      margin-left: 8px;

      p {
        font-size: 14px;
      }
    }
  }
}
