.table td {
  vertical-align: middle;
}

.image-collection-wrapper {
  position: relative;
  height: 100px;

  .image-wrapper {
    width: 100px;
    height: 100%;
    overflow: hidden;
    border: 3px solid white;
    border-radius: 7px;
    position: absolute;
    box-shadow: 0 0 4px rgba(#000, 0.05);
    transition: left 200ms;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }
  }

  @for $i from 1 through 5 {
    .number-#{$i} {
      left: #{$i * 12}px;
    }
  }

  &:hover {
    @for $i from 1 through 5 {
      .number-#{$i} {
        left: #{$i * 90}px;
      }
    }
  }
}
