.o__reviews-index {
  display: flex;
  flex-direction: column;
  margin: 0 0 24px;

  .add-product-button {
    z-index: 99;
    position: sticky;
    top: 8px;
    margin: 0 8px 16px;
  }

  .table-container {
    max-width: 1100px;
    min-width: 900px;
    width: auto;
  }

  .table-wrapper {
    min-width: 100%;

    img {
      max-width: 150px;
    }
  }
}
