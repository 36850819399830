.portal-morph {
  display: flex;
  z-index: 999;

  button {
    &:hover,
    &:focus {
      outline: none;
    }
  }

  .--hidden {
    display: none;
  }

  .--zoom-in {
    #morph-canvas {
      cursor: zoom-in;
    }
  }

  .--zoom-out {
    #morph-canvas {
      cursor: zoom-out;
    }
  }

  // Most of these should be defaults in styleguide
  .portal__wrapper {
    display: flex;
    flex-direction: column;
  }

  .portal__container {
    background-color: black;
  }

  .portal__content {
    padding: 40px;
    height: 100%;
  }

  .portal-content--container {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    max-width: 520px;

    &.--wide-tools {
      .morph-canvas {
        left: -320px;
      }
    }
  }

  .morph-canvas {
    position: absolute;
    margin: 0 auto;
    width: fit-content;
    left: 0;
  }

  .morph-buttons {
    z-index: 99;
    display: flex;
    align-items: center;
    position: fixed;
    width: 290px;
    height: 100px;
    right: 0;
    bottom: 0;
    padding: 1em;
    background-color: rgba(33, 33, 33, 1);

    .original-button {
      input {
        display: none;
      }

      label {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0.75rem;
        height: fit-content;
        min-height: 3rem;
        min-width: 3rem;
        box-sizing: border-box;
        outline: none;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        color: #fff;
        transition: all 0.3s;
      }
    }
  }

  .measurements-wrapper,
  .stills-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
      display: flex;
      justify-content: center;
      align-content: center;
      width: 100%;

      &.active {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }

    p {
      margin: 0.3rem 0;
      color: #fff;
    }

    svg path {
      stroke: #fff;
    }

    .icon-holder {
      width: 32px;
      height: 32px;
      display: inline-block;
      margin-right: 16px;
    }

    .loading-icon {
      display: inline-block;
    }

    .icon-download {
      border-bottom: none;
    }
  }
}
