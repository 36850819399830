.transcription-editor {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &__text {
    flex-grow: 1;

    textarea {
      flex-grow: 1;
    }
  }

  &__action {
    margin-left: 24px;
    align-self: center;
    cursor: pointer;

    svg {
      color: #ce6e6c;
      font-size: 24px;
      vertical-align: center;
    }
  }
}
