.morph-tools {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  margin: 0 0 0 1em;
  color: white;
  overflow: hidden;

  .buttons-wrapper {
    button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 0.8rem 0.5rem 0.5rem;
      border: 0;
      border-radius: 0;
      background-color: transparent;
      color: #fff;

      svg path {
        stroke: #fff;
      }

      p {
        margin: 0;
      }

      &.active {
        background-color: rgba(#e2e2e2, 0.15);
      }
    }

    span {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .slider-wrapper {
    position: relative;
    padding: 0 0 100px;
    overflow: auto;
    width: 290px;
    background-color: rgba(#e2e2e2, 0.15);

    &.--wide-tools {
      width: 640px;
    }

    form {
      padding: 2em 1rem;

      .BEURO__aedit-input-field > .input-wrapper {
        margin: 0;

        label {
          text-transform: none;
        }
      }
    }
  }

  .morph-buttons {
    z-index: 99;
    display: flex;
    align-items: center;
    position: fixed;
    width: 290px;
    height: 100px;
    right: 0;
    bottom: 0;
    padding: 1em;
    background-color: rgba(33, 33, 33, 1);

    .original-button {
      input {
        display: none;
      }

      label {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0.75rem;
        height: fit-content;
        min-height: 3rem;
        min-width: 3rem;
        box-sizing: border-box;
        outline: none;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        color: #fff;
        transition: all 0.3s;
      }
    }
  }
}
